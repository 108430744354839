<template>
   <div>
      <div class="card-toolbar"></div>
      <Skeleton2 v-if="loadingAllActions" />
      <vc-calendar
         v-else
         class="custom-calendar w-100"
         :masks="masks"
         :attributes="attributes"
         disable-page-swipe
         is-expanded
         :from-page="{ month: month, year: year }"
      >
         <template v-slot:day-content="{ day, attributes }">
            <div class="d-flex flex-row h-100 z-10 overflow-hidden">
               <span class="day-label text-sm text-dark">{{ day.day }}</span>
               <div class="overflow-auto pl-1 pt-2">
                  <p
                     v-for="(attr, index) in attributes"
                     :class="`text-xs rounded-sm p-1 mt-0 mb-1 bg-${attr.customData.color} text-white`"
                     v-bind:key="index"
                  >
                     <router-link
                        class="text-white font-weight-bold"
                        :to="{ name: 'action.view', params: { id: attr.customData.acl_id, societe: attr.customData.act_soc } }"
                     >
                        {{ attr.customData.name }} - {{ attr.customData.acl_pro_libelle }}
                     </router-link>
                  </p>
               </div>
            </div>
         </template>
      </vc-calendar>
      <div class="row" :class="isMobile() ? 'mt-5' : ''">
         <div class="col-xxl-12">
            <ActionLegend />
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import { RESET_TOOLBAR, SET_TOOLBAR } from '@/core/services/store/config.module';
import { GET_ACTION_PLANNING } from '@/core/services/store/api-orion/action';
import Skeleton2 from '@/view/content/skeleton/Skeleton2';
import ActionLegend from '@/view/pages/action/widget/Legend';
import moment from 'moment';

export default {
   props: {
      month: {
         type: Number,
         default: moment().month() + 1
      },
      year: {
         type: Number,
         default: moment().year()
      }
   },
   data: () => ({
      attributes: [],
      loadingAllActions: true
   }),
   components: {
      Skeleton2,
      ActionLegend
   },
   async mounted() {
      await this.getAllActions();
      this.setPageTitle();
      this.setToolbar();
   },
   beforeRouteChange(to, from, next) {
      this.$store.dispatch(RESET_TOOLBAR).then(next);
   },
   computed: {
      ...mapGetters({
         planningDates: 'getActionPlanning'
      })
   },
   methods: {
      storeActions() {
         this.attributes = [];
         this.planningDates.forEach((planningDate, index) => {
            this.attributes.push({
               key: index,
               customData: Object.assign(planningDate, {
                  color:
                     moment(planningDate.date_end).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
                        ? 'primary'
                        : planningDate.acl_confirme
                        ? 'success'
                        : 'warning'
               }),
               dates: { start: new Date(planningDate.pda_date), end: new Date(planningDate.pda_date) }
            });
         });
      },
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.CALENDAR'));
      },
      setToolbar() {
         this.$store.dispatch(SET_TOOLBAR, this.$t('TIP.ACTION.CALENDAR'));
      },
      async getAllActions() {
         await this.$store
            .dispatch(GET_ACTION_PLANNING)
            .then(() => {
               this.storeActions();
            })
            .catch((error) => {
               if (error.status === 404) {
                  this.$router.push({ name: '404' });
               }
            });
      }
   },
   watch: {
      attributes() {
         this.loadingAllActions = false;
      }
   }
};
</script>
